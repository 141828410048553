import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart, updateQuantity, bulkRemoveFromCart } from '../redux/reducer/cartSlice';
import { productCart, productCartDelete, productCartQuantityUpdate } from '../api/apiProduct';

const useCart = () => {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);

    const toggleCart = (productId, quantity = null, selectedSpecificationId = [], cartId=null, is_delete=false) => {
        if(is_delete || quantity == 0){
            let data = {}
            if(Array.isArray(cartId)){
                data.cart_ids = cartId;
            }else{
                data.cart_id = cartId;
            }
            productCartDelete(data).then((res)=>{
                dispatch(removeFromCart({cart_id: cartId}))
            }).catch((err)=>{

            })
        }else{
            if(cartId){
                let data = {cart_id: cartId, quantity: quantity};
                if(selectedSpecificationId.length > 0){
                    data.specifications = {specification_id : selectedSpecificationId[0].specificationId, varient_id: selectedSpecificationId[0].itemId};
                }
                productCartQuantityUpdate(data).then((res)=>{
                    dispatch(updateQuantity({ cart_id: cartId, id: productId, quantity: parseInt(quantity) }))
                }).catch((err)=>{

                })
            
            }
            else{

                let data = { product_id: productId }
                const isCart = cart.some(item => item.id === productId);
                productId = parseInt(productId);
                if (quantity) {
                    data.quantity = quantity;
                }
                let specifications = [];
                selectedSpecificationId.forEach((spec) => {
                    const existingSpec = specifications.find(item => spec.specificationId === item?.specification_id);
                    if (!existingSpec) {
                        if (spec.items) {
                            const checkListItems = spec.items.map(item => ({
                                specification_id: spec.specificationId,
                                varient_id: item.id,
                                checkList: item.checkList.map(c => c.id),
                            }));
                
                            specifications.push({
                                values: checkListItems,
                            });
                        } else {
                            specifications.push({
                                values: [{
                                    specification_id: spec.specificationId,
                                    varient_id: spec.itemId,
                                }],
                            });
                        }
                    } else {
                        const isDuplicate = existingSpec.values.some(
                            (value) => value.specification_id === spec.specificationId && value.varient_id === spec.itemId
                        );
                
                        if (!isDuplicate) {
                            if (spec.items) {
                                spec.items.forEach(item => {
                                    const existingItem = existingSpec.values.find(value => value.varient_id === item.id);
                                    if (!existingItem) {
                                        existingSpec.values.push({
                                            specification_id: spec.specificationId,
                                            varient_id: item.id,
                                            checkList: item.checkList.map(c => c.id),
                                        });
                                    } else {
                                        const isCheckListDuplicate = existingItem.checkList.some(id =>
                                            item.checkList.map(c => c.id).includes(id)
                                        );
                                        if (!isCheckListDuplicate) {
                                            existingItem.checkList.push(...item.checkList.map(c => c.id));
                                        }
                                    }
                                });
                            } else {
                                existingSpec.values.push({
                                    specification_id: spec.specificationId,
                                    varient_id: spec.itemId,
                                });
                            }
                        }
                    }
                });
                const combinedValues = specifications.flatMap(spec => spec.values);
                if (specifications.length > 0) {
                    data.specifications = combinedValues;
                }
                productCart(data).then((res) => {
                    if (res.data.status == "success") {
                        if(res.data.is_new){
                            dispatch(addToCart({ cart_id: res.data.id, id: productId, quantity: quantity }));
                        }else{
                            dispatch(updateQuantity({ cart_id: res.data.id, id: productId, quantity: quantity }))
                        }
                    }
                }).catch((err) => {

                })

            }
        }
    }
    return toggleCart;
}

export default useCart