import React,{useState,useRef,useEffect } from 'react'
import { Link, useNavigate  } from 'react-router-dom';
import { productCartList, productCartQuantityUpdate } from '../../api/apiProduct';
import { marketImageUrl } from '../../api/Url';
import { useSelector } from 'react-redux';
import useCart from '../../customHooks/useCart';

const Sidecart = ({showSideCart}) => {
  const [quantity1, setQuantity1] = useState(1);
  const [quantity2, setQuantity2] = useState(1);
  const handleIncrement1 = () => setQuantity1(quantity1 + 1);
  const handleDecrement1 = () => quantity1 > 1 && setQuantity1(quantity1 - 1);
  const handleIncrement2 = () => setQuantity2(quantity2 + 1);
  const handleDecrement2 = () => quantity2 > 1 && setQuantity2(quantity2 - 1);
  const sideCartRef = useRef(null);





  const navigate = useNavigate();
  const toggleCart = useCart();

  const cart = useSelector((state) => state.cart);
  const selectedCurrency = useSelector((state) => state.currentCurrency);
  
  const [cartData, setCartData] = useState([]);
  const [subTotal, setSubTotal] = useState(0)
  useEffect(()=>{
    let data = {dimension: "100x100"}
    productCartList(data).then((res)=>{
      setCartData(res.data.data.data);
    }).catch((err)=>{

    })
  },[cart])

  const handleQuantity = (cartId, productId, quantity) => {
    toggleCart(productId, quantity, [], cartId);
  }
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (sideCartRef.current && !sideCartRef.current.contains(event.target)) {
  //       setShowSideCartPopup(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [setShowSideCartPopup]);
  const handleCheckoutClick = () => {
    if(cart.length > 0){
      navigate('/checkout');
    }
  };
  useEffect(() => {
    // if (cart.length > 0) {
      const totalPrice = cart.reduce((accumulator, item) => {
        const quantity = item.quantity || 0;
        const itemData = cartData.find((cd) => cd.cart_id === item.cart_id);
        const selectedSpecificationCost = itemData?.selected_specification?.specifications
          ? itemData.selected_specification.specifications.reduce((specTotal, spec) => {
              return specTotal + (parseInt(spec.cost) || 0);
            }, 0)
          : 0;
        return accumulator + (parseInt(itemData?.price) + parseInt(selectedSpecificationCost)) * quantity;
      }, 0);
  
      setSubTotal(totalPrice);
    // }
  }, [cart, cartData]);
  return (

        <div id="sidecart" className={`root-sidecart ${showSideCart ? "" : "d-none"}`}  >
          <div className="cart-wrap">
            <div className="sidecart-body">
              <div className="sidecart-main">
                <div className="group-header-containers">
                  <div data-id="app_cart_head_component_cart_header"></div>
                </div>
                <div className="sidecart-list">
                  <div className="infinite-scroll-component__outerdiv">
                    <div className="infinite-scroll-component comet-v2-infinite-scroll" style={{height: '100%', overflow: 'hidden auto'}}>
                      <div className="group-containers">
                      {cart?.map((item, key) => {
                        const data = cartData.find((cd)=>cd.id === item.id)
                        return(
                        <div className="group-shop-containers" key={key}>
                          <div className="cart-stores">
                            <div className="cart-store-contents">
                              <span className="cart-store-content-titles">{data?.title}</span>
                            </div>
                          </div>
                          <div className="cart-products app_cart_product_component_group">
                            <div className="cart-product-bodys" style={{opacity: '1'}}>
                              <div className="cart-product-checkbox-wraps">
                                <div className="cart-product-checkbox-bg"></div>
                                <label className="comet-v2-checkbox comet-v2-checkbox-checked cart-product-checkbox">
                                  <span className="comet-icon comet-icon-radio ">
                                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                      <path d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m238.933333 347.733334l-277.333333 266.666666c-6.4 6.4-17.066667 10.666667-25.6 10.666667h-2.133333c-10.666667 0-19.2-6.4-27.733334-14.933333l-128-160c-12.8-17.066667-10.666667-40.533333 6.4-53.333334 17.066667-12.8 40.533333-10.666667 53.333334 6.4l102.4 128 247.466666-238.933333c14.933333-14.933333 38.4-12.8 53.333334 0 12.8 17.066667 12.8 40.533333-2.133334 55.466667z"></path>
                                    </svg>
                                  </span>
                                  <input type="checkbox" className="comet-v2-checkbox-input" value="" checked="" />
                                </label>
                              </div>
                              <Link className="cart-product-img-wrap" to="#" target="_blank">
                                <span className="cart-product-img" style={{
                                    backgroundImage: `url(${marketImageUrl() + data?.attachment[0]?.attachment})`,
                                    opacity: '1',
                                }}></span>
                              </Link>
                            </div>
                            <div className="cart-product-price">
                              <div className="cart-product-price-text">{selectedCurrency?.symbol}{data?.price}</div>
                            </div>
                            <div className="cart-product-action">
                              <div className="comet-v2-input-number">

                                <div className={`comet-v2-input-number-btn  ${item.quantity <= 0 ? 'comet-v2-input-number-btn-disabled' : ''}`} onClick={()=>handleQuantity(item.cart_id, item.id, item.quantity-1)}>
                                  <span className="comet-icon comet-icon-subtract ">
                                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                      <path d="M170.666667 506.666667c0-20.608 16.725333-37.333333 37.333333-37.333334h608a37.333333 37.333333 0 1 1 0 74.666667H208A37.333333 37.333333 0 0 1 170.666667 506.666667z"></path>
                                    </svg>
                                  </span>
                                </div>
                                <input type="text" className="comet-v2-input-number-input" value={item.quantity} />
                                <div className="comet-v2-input-number-btn">
                                  <span className="comet-icon comet-icon-add " onClick={()=>handleQuantity(item.cart_id, item.id, item.quantity+1)}>
                                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                      <path d="M522.666667 844.8c-21.333333 0-38.4-17.066667-38.4-38.4l2.133333-270.933333-270.933333 2.133333c-21.333333 0-36.266667-17.066667-38.4-36.266667 0-21.333333 17.066667-38.4 36.266666-38.4l270.933334-2.133333 2.133333-270.933333c0-21.333333 17.066667-36.266667 38.4-36.266667s36.266667 17.066667 36.266667 38.4l-2.133334 270.933333 270.933334-2.133333c21.333333 0 36.266667 17.066667 38.4 36.266667 0 21.333333-17.066667 38.4-36.266667 38.4l-270.933333 2.133333-2.133334 270.933333c2.133333 19.2-14.933333 36.266667-36.266666 36.266667z"></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>)})}
                        {/* <div className="group-shop-containers">
                          <div className="cart-stores">
                            <div className="cart-store-contents">
                              <span className="cart-store-content-titles">TRSOYEWATCH Store</span>
                            </div>
                          </div>
                          <div className="cart-products app_cart_product_component_group">
                            <div className="cart-product-bodys" style={{opacity: '1'}}>
                              <div className="cart-product-checkbox-wraps">
                                <div className="cart-product-checkbox-bg"></div>
                                <label className="comet-v2-checkbox comet-v2-checkbox-checked cart-product-checkbox">
                                  <span className="comet-icon comet-icon-radio ">
                                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                      <path d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333z m238.933333 347.733334l-277.333333 266.666666c-6.4 6.4-17.066667 10.666667-25.6 10.666667h-2.133333c-10.666667 0-19.2-6.4-27.733334-14.933333l-128-160c-12.8-17.066667-10.666667-40.533333 6.4-53.333334 17.066667-12.8 40.533333-10.666667 53.333334 6.4l102.4 128 247.466666-238.933333c14.933333-14.933333 38.4-12.8 53.333334 0 12.8 17.066667 12.8 40.533333-2.133334 55.466667z"></path>
                                    </svg>
                                  </span>
                                  <input type="checkbox" className="comet-v2-checkbox-input" value="" checked="" />
                                </label>
                              </div>
                              <Link className="cart-product-img-wrap" to="#" target="_blank">
                                <span className="cart-product-img" style={{backgroundImage: 'url("https://ae01.alicdn.com/kf/S2ea7eb50b5a84f0a85bf917e995c3f19d.jpg")',opacity: '1'}}></span>
                              </Link>
                            </div>
                            <div className="cart-product-price">
                              <div className="cart-product-price-text">UGX8,316</div>
                            </div>
                            <div className="cart-product-action">
                              <div className="comet-v2-input-number">
                              <div className={`comet-v2-input-number-btn  ${quantity2 <= 0 ? 'comet-v2-input-number-btn-disabled' : ''}`} onClick={handleDecrement2}>
                                  <span className="comet-icon comet-icon-subtract ">
                                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                      <path d="M170.666667 506.666667c0-20.608 16.725333-37.333333 37.333333-37.333334h608a37.333333 37.333333 0 1 1 0 74.666667H208A37.333333 37.333333 0 0 1 170.666667 506.666667z"></path>
                                    </svg>
                                  </span>
                                </div>
                                <input type="text" className="comet-v2-input-number-input" value={quantity2} />
                                <div className="comet-v2-input-number-btn" >
                                  <span className="comet-icon comet-icon-add " onClick={handleIncrement2}>
                                    <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor" aria-hidden="false" focusable="false">
                                      <path d="M522.666667 844.8c-21.333333 0-38.4-17.066667-38.4-38.4l2.133333-270.933333-270.933333 2.133333c-21.333333 0-36.266667-17.066667-38.4-36.266667 0-21.333333 17.066667-38.4 36.266666-38.4l270.933334-2.133333 2.133333-270.933333c0-21.333333 17.066667-36.266667 38.4-36.266667s36.266667 17.066667 36.266667 38.4l-2.133334 270.933333 270.933334-2.133333c21.333333 0 36.266667 17.066667 38.4 36.266667 0 21.333333-17.066667 38.4-36.266667 38.4l-270.933333 2.133333-2.133334 270.933333c2.133333 19.2-14.933333 36.266667-36.266666 36.266667z"></path>
                                    </svg>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="group-line-divider"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div data-id="app_cart_recommend_component_85065"></div>
              </div>
              <div className="cart-side">
                <div className="cart-summary">
                  <div className="cart-summary-top">{selectedCurrency?.symbol} {subTotal}</div>
                  {/* <div className="cart-summary-bottom" style={{color: 'rgb(102, 102, 102)', fontSize: '12px' }}>(≈ UGX21541)</div> */}
                  <button type="button" className="comet-v2-btn comet-v2-btn-primary comet-v2-btn-block cart-summary-button comet-v2-btn-important" onClick={handleCheckoutClick}>
                    <span>Checkout</span>
                  </button>
                  <Link to={"/cart"} className="cart-summary-tocart">Go to cart</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

  )
}

export default Sidecart